import styled from 'styled-components';
import {device} from '../Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'
import BackgroundImg from '../../../assets/institute-images/footer-bg.jpg';

export const FooterSection = styled.footer`
    background-size:100%;
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    position:relative;
    padding:60px 0px 0px;

    @media ${device.tablet} {
        padding: 60px 10px 0px;
    }
`;

export const FooterInner = styled.div`
`;

export const FooterCol = styled.div`
    padding:20px 0px;
    text-align:left;

    ul{
        margin-left:0px;
        margin-bottom:0px;
    }

    a{
        text-decoration:none;
        color:#fff;
        :hover{
            text-decoration:underline;
        }
    }

    @media ${device.tablet} {
        padding:20px 0px;
    }
    h5{
        margin-bottom:25px;
        text-transform:uppercase;
        text-align:left;
        color:#fff;

        @media ${device.laptop} {
            font-size:24px;
        }
    }
    p{
        margin-bottom:5px;
        color:#fff;
    }
    ul{
        padding-left:0px;
        margin-left:0px;
    }
    ul li{
        list-style:none;
        margin-bottom:6px;

        :last-child{
            margin-bottom:0px;
        }
    }
    img{
        max-width: 170px;

        @media ${device.laptop} {
            max-width: 140px;
        }

        @media ${device.tablet} {
            max-width: 170px;
        }
    }
`;



export const FooterMenu = styled.a`
    color:#595959;
    text-decoration:none;
    font-size:15px;
    :hover
    {
        text-decoration:underline;
    }
`;

export const FooterSocial = styled.a`
    margin-right:10px;
`;

export const BottomFooterWrapper = styled.div`
    width:100%;
    padding:60px 0px 20px;
    text-align:center;
    background-size:100%;
`;

export const BottomFooterPara = styled.p`
    margin-bottom:0px;
    padding:10px 0px;
    font-size:15px;
    line-height: 15px;
    color:#fff;
`;

export const BottomLink = styled.a`
    font-size:15px;
    margin-bottom:0px;
    padding:10px 0px;
    color:#fff;
    line-height: 15px;
    text-decoration:none;
    :hover{
        text-decoration:underline;
    }
`;


export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    padding: 6px;
    border-radius:100%;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        background:#ed008d;
        color:#fff;
    }
`;
export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    padding: 6px;
    border-radius:100%;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;
export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    border-radius:100%;
    padding: 6px;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        background:#00adee;
        color:#fff;
    }
`;
export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    border-radius:100%;
    padding: 6px;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;
