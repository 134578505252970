import React from 'react';
import {ImageTextSection,CustomRow,TextLayout,ContactUsBtn,ImageHolder
} from './imagetext1.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText1 = () => {
    const JSONData  = useStaticQuery(graphql`{
  instituteJson {
    ImageText1 {
      Heading
      Description1
      Description2
      ContactUsBtn
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
        return (
            <ImageTextSection id="imagetext1Container">
                <Container>
                    <CustomRow>
                        <Col md={12} lg={6}>
                            <TextLayout>
                                <h4>
                                {JSONData.instituteJson.ImageText1.Heading}
                                </h4>
                                <p>
                                {JSONData.instituteJson.ImageText1.Description1}
                                </p>
                                <p>
                                {JSONData.instituteJson.ImageText1.Description2}
                                </p>
                                <ContactUsBtn>
                                {JSONData.instituteJson.ImageText1.ContactUsBtn}
                                </ContactUsBtn>
                            </TextLayout>
                        </Col>
                        <Col md={12} lg={6}>
                            <ImageHolder>
                                <GatsbyImage
                                    image={JSONData.instituteJson.ImageText1.Img.childImageSharp.gatsbyImageData}
                                    alt="" />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                </Container>
            </ImageTextSection>
        );
}

export default ImageText1;
