import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn} from '../Common/common.style';
import {PlayCircle} from '@styled-icons/boxicons-regular/PlayCircle';
import BackgroundImg from '../../../assets/institute-images/banner.jpg';

export const BannerSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position-x: center;

    min-height: 100vh;
    padding:160px 0px 100px;
    display:flex;
    align-items:center;

    @media ${device.laptopM} {
        background-position-x: 70%;
    }
    @media ${device.laptopM} {
        background-position-x: 90%;
    }
    @media ${device.tablet} {
        background-position-x: 90%;
        padding:140px 10px 80px;
    }
`;

export const BannerContents = styled.div`
    display:flex;
    align-items:center;
`;

export const BannerContentsLeft = styled.div`
    width:55%;

    @media ${device.laptop} {
        width:65%;
    }
    @media ${device.tablet} {
        width:100%;
    }

    h1{
        color:#fff;
        text-transform: uppercase;
        font-weight: 600;
    }
    p{
        color:#fff;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
        font-weight: 600;
        font-family: 'Barlow', sans-serif;
        opacity: 0.9;
        
        @media ${device.tablet} {
            text-align:center;
        }
    }
`;


export const ButtonLayout = styled.div`
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        justify-content:center;
    }
    @media ${device.mobileL} {
        flex-direction:column;
    }
`;

export const CallNowBtn = styled(Commonbtn)`

`;

export const WatchVideoAnchor = styled.a`
    cursor:pointer;
    transition: all 0.3s ease-in-out;

    :hover{
        transition: all 0.3s ease-in-out;
        transform:scale(1.05);
        text-decoration:none;
        color:#fff;
    }
`;

export const WatchVideoLayout = styled.div`
    display:flex;
    align-items:center;
    margin-left:20px;
    background:#00000066;
    padding:7px 30px;
    border-radius:5px;

    @media ${device.mobileXL} {
        padding:5px 20px;
    }

    @media ${device.mobileL} {
        margin-top:20px;
        margin-left:0px;
    }
`;

export const PlayIcon = styled(PlayCircle)`
    width: 28px;
    height: 28px;
    color:#fff;
`;

export const PlayText = styled.span`
    color:#fff;
    text-transform:uppercase;
    margin-left:5px;
`;
