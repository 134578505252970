import styled from 'styled-components';
import {device} from '../Common/device';
import BackgroundImg from '../../../assets/institute-images/features-banner.jpg';

export const FeaturesSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const FeaturesSingle = styled.div`
    text-align:center;
    padding:10px;

    img{
        width:100px;
        height:100px;
        margin-bottom:20px;
    }
    h6{
        color:#fff;
        font-weight: 700;
        margin-bottom:10px;
    }
    p{
        color:#fff;
        text-align:center;
    }
`;
