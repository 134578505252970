import React,{Component}from 'react';
import {TestimonialWrapper,HeadingLayout,TestimonialHeading,
    SliderOuterWrapper,LeftIcon,ImgButtonLeft,SliderWrapper,TestimonialCard,ImageLayout,
    TextLayout,StyledIconOpen,QuoteHolder,StyleIconClose,
    ImgButtonRight,RightIcon
} from './testimonials.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            className: "center-portfolio",
            centerMode: true,
            infinite: true,
            arrows:true,
            centerPadding:"0px",
            slidesToShow: 1,
            speed: 1000
        };

        return (
            <TestimonialWrapper id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md={12}>
                            <HeadingLayout>
                                <TestimonialHeading>
                                {this.props.instituteJson.Testimonials.TestimonialHeading}
                                </TestimonialHeading>
                            </HeadingLayout>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                        <SliderOuterWrapper>
                            <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                <LeftIcon />
                            </ImgButtonLeft>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.instituteJson.Testimonials.TestimonialCards.map((item,idx) => {
                                return (
                                    <SliderWrapper>
                                        <TestimonialCard>
                                            <ImageLayout>
                                                <GatsbyImage
                                                    image={item.Img.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    className="authorImg"
                                                    imgStyle={{
                                                        objectPosition:"top"
                                                    }} />
                                            </ImageLayout>
                                            <TextLayout>
                                                <StyledIconOpen />
                                                <p>
                                                {item.TestimonialText}
                                                </p>
                                                <QuoteHolder>
                                                    <StyleIconClose />
                                                </QuoteHolder>
                                                <h6>
                                                {item.Author}
                                                </h6>
                                                <span>
                                                {item.Designation}
                                                </span>
                                            </TextLayout>
                                        </TestimonialCard>
                                        </SliderWrapper>
                                );
                                })
                            }
                            </Slider>
                            <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                <RightIcon />
                            </ImgButtonRight>
                            </SliderOuterWrapper>
                        </Col>
                    </Row>
                </Container>
            </TestimonialWrapper>
        );
    }
}
const TestimonialsSection = () => (
    <StaticQuery
        query={graphql`{
  instituteJson {
    Testimonials {
      TestimonialHeading
      TestimonialCards {
        Img {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        TestimonialText
        Author
        Designation
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Testimonials instituteJson={data.instituteJson}/>
        )}
    />
  )
  export default TestimonialsSection;
