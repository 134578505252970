import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const ImageTextSection = styled.section`
    padding:100px 0px 50px;

    @media ${device.tablet} {
        padding:80px 5px 40px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }

    h4{
        text-align:left;
        text-transform:uppercase;
        font-weight:700;
    }
`;


export const ContactUsBtn = styled(Commonbtn)`

`;

export const ImageHolder = styled.div`
    padding-left:50px;

    @media ${device.laptop} {
        padding-left:0px;
    }
`;
