import {createGlobalStyle} from 'styled-components';
import {device} from './device';

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Barlow', sans-serif;
        font-weight: 700;
        margin-bottom:20px;
    }

    h1{
        font-size:50px;
        line-height:56px;
        font-weight: 600;

        @media ${device.laptop} {
            font-size:45px;
            line-height:50px;
        }
    
        @media ${device.tablet} {
            text-align:center;
        }
        @media ${device.mobileXL} {
            font-size:40px;
            line-height:45px;
        }
    }
    h2{
        font-size:42px;
        line-height:48px;

        @media ${device.tablet} {
            font-size:40px;
            line-height:46px;
        }
    }
    h3{
        font-size:36px;
        line-height:42px;
    }
    h4{
        font-size:30px;
        line-height:36px;

        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:26px;
        line-height:30px;
    }
    h6{
        font-size:24px;
        line-height:30px;
        font-weight: 600;

        @media ${device.tablet} {
            text-align:center;
        }
    }
    p{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color: #666666;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 1.75;
    }
    ul li{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color: #666666;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 25px;
    }
    a{
        font-size:16px;
        font-family: 'Poppins', sans-serif;
        font-weight:500;
        text-decoration:none;
    }
    img{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
