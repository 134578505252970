import React from "react";
import Banner from "../containers/Institute/Banner";
import Courses from "../containers/Institute/Courses";
import Features from "../containers/Institute/Features";
import ImageText1 from "../containers/Institute/ImageText1";
import ImageText2 from "../containers/Institute/ImageText2";
import ImageText3 from "../containers/Institute/ImageText3";
import About from "../containers/Institute/About";
import Testimonials from "../containers/Institute/Testimonials";
import Contact from "../containers/Institute/Contact";
import HeaderMenuBS from "../containers/Institute/HeaderMenuBS";
import Footer from "../containers/Institute/Footer";
import GlobalStyle from "../containers/Institute/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import BuyNow from "../components/BuyNow";
import Fonts from "../containers/Institute/Common/fonts"

const Institute = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenuBS/>
       <main>
            <Seo 
                title="Institute ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy Institute Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <Courses/>
            <Features/>
            <ImageText1/>
            <ImageText2/>
            <ImageText3/>
            <About/>
            <Testimonials/>
            <Contact/>
            
        </main>
       <Footer/>
   </div>

)

export default Institute;
