import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const ImageTextSection = styled.section`
    padding:50px 0px 100px;

    @media ${device.tablet} {
        padding:40px 5px 80px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }

    h4{
        text-align:left;
        text-transform:uppercase;
        font-weight:700;
    }
    ul{
        list-style:none;
        margin:0;
    }
    ul li{
        display:flex;
        margin-bottom: 10px;
    }
    ul li p{
        margin-bottom:0;
        margin-left:15px;
        line-height:25px;
    }
`;



export const LeftIcon = styled.img`
    height:30px;
    width:30px;
    flex-shrink:0;
    margin-top:5px;
`;


export const ContactUsBtn = styled(Commonbtn)`
    margin-top:15px;
`;

export const ImageHolder = styled.div`
    padding-left:50px;

    @media ${device.laptop} {
        padding-left:0px;
    }
`;
