import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {RightArrow} from '@styled-icons/boxicons-regular/RightArrow';

export const ImageTextSection = styled.section`
    padding:50px 0px;

    @media ${device.tablet} {
        padding:40px 5px 40px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
    @media ${device.laptop} {
        flex-direction:column-reverse;
    }
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
    h4{
        text-align:left;
        text-transform:uppercase;
        font-weight:700;
    }
    ul{
        list-style:none;
        margin:0;
    }
    ul li{
        display:flex;
        margin-bottom: 10px;
    }

    ul li p{
        margin-bottom:0;
        margin-left:15px;
        line-height:25px;
    }
`;

export const RightArrowIcon = styled(RightArrow)`
    color:#ef5350;
    height:20px;
    width:20px;
    flex-shrink:0;
    margin-top:5px;
`;

export const ContactUsBtn = styled(Commonbtn)`
    margin-top:15px;
`;

export const ImageHolder = styled.div`
    padding-right:50px;

    @media ${device.laptop} {
        padding-right:0px;
    }
`;
