import React from 'react';
import {BannerSection,BannerContents,BannerContentsLeft
    ,ButtonLayout,CallNowBtn,WatchVideoAnchor,WatchVideoLayout,
    PlayIcon,PlayText
} from './banner.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Banner{
                        BannerHeading
                        BannerDescription
                        WatchVideoAnchor
                        BtnText
                    }
                }
            }
    `);
    
    return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentsLeft>
                        <h1>
                            {JSONData.instituteJson.Banner.BannerHeading}
                        </h1>
                        <p>
                            {JSONData.instituteJson.Banner.BannerDescription}
                        </p>
                        <ButtonLayout>
                            <CallNowBtn>{JSONData.instituteJson.Banner.BtnText}</CallNowBtn>
                            <WatchVideoAnchor>
                                <WatchVideoLayout>
                                    <PlayIcon />
                                    <PlayText>
                                        {JSONData.instituteJson.Banner.WatchVideoAnchor}
                                    </PlayText>
                                </WatchVideoLayout>
                            </WatchVideoAnchor>
                        </ButtonLayout>
                    </BannerContentsLeft>
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;
