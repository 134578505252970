import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn,SectionHeading} from '../Common/common.style'
import {Row} from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';

export const ContactWrapper = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const LogoLayout = styled.div`
    display:flex;
    flex-wrap:wrap;
`;

export const LogoHolder = styled.div`
    width:33.33%;
    padding:30px;
`;

export const HeadingLayout = styled.div`

`;

export const ContactFormHeading = styled(SectionHeading)`

`;

export const ContactFormRight = styled.div`
    margin-left:20px;
    padding:40px;
    background:#fff;
    box-shadow: 0px 0px 17px 1px #ececec;

    @media ${device.laptop} {
        margin-left:0px;
        padding:30px;
    }
    @media ${device.tablet} {
        margin-left:0px;
        padding:20px;
    }
`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:30px;

    .react-datepicker-wrapper{
        width:100%;
    }
`;

export const FormGroupSubmit = styled.div`
    margin-bottom:0px;

    .react-datepicker-wrapper{
        width:100%;
    }
`;

export const NameInput = styled.input`
    width: 100%;
    outline:0;
    background:#f7f7f7;
    padding: 10px 20px;
    border: none;
    border-radius:2px;
    font-size:14px;
    ::placeholder
    {
        font-size:14px;
    }
`;

export const ContactFormSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextCenter = styled.div`
    text-align:center;
`;

export const SubmitBtn = styled(Commonbtn)`
    font-size:15px;
    font-weight:bold;
`;

export const ContactFormSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;
