import React from 'react';
import {CoursesSection,HeadingLayout,CoursesHeading,CoursesCard,TextLayout,
    DetailsList,DetailsListItem,RightArrowIcon,DetailsText,ApplyNowBtn
} from './courses.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const Courses = () => {
    const JSONData  = useStaticQuery(graphql`{
  instituteJson {
    Courses {
      SectionHeading
      CourseImg1 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      CourseName1
      CourseDesc1
      DetailsText1
      DetailsText2
      CourseImg2 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      CourseName2
      CourseDesc2
      DetailsText3
      DetailsText4
      CourseImg3 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      CourseName3
      CourseDesc3
      DetailsText5
      DetailsText6
      ApplyNowBtn
    }
  }
}
`);
        return (
            <CoursesSection id="coursesContainer">
                <Container>
                    <Row>
                        <Col md={12}>
                            <HeadingLayout>
                                <CoursesHeading>
                                {JSONData.instituteJson.Courses.SectionHeading}
                                </CoursesHeading>
                            </HeadingLayout>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <CoursesCard>
                                <GatsbyImage
                                    image={JSONData.instituteJson.Courses.CourseImg1.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="coursesImg" />
                                <TextLayout>
                                    <h6>
                                    {JSONData.instituteJson.Courses.CourseName1}
                                    </h6>
                                    <p>
                                    {JSONData.instituteJson.Courses.CourseDesc1}
                                    </p>
                                    <DetailsList>
                                        <DetailsListItem>
                                            <RightArrowIcon />
                                            <DetailsText>
                                            {JSONData.instituteJson.Courses.DetailsText1}
                                            </DetailsText>
                                        </DetailsListItem>
                                        <DetailsListItem>
                                            <RightArrowIcon />
                                            <DetailsText>
                                            {JSONData.instituteJson.Courses.DetailsText2}
                                            </DetailsText>
                                        </DetailsListItem>
                                    </DetailsList>
                                    <ApplyNowBtn>
                                    {JSONData.instituteJson.Courses.ApplyNowBtn}
                                    </ApplyNowBtn>
                                </TextLayout>
                            </CoursesCard>
                        </Col>
                        <Col lg={4}>
                            <CoursesCard>
                                <GatsbyImage
                                    image={JSONData.instituteJson.Courses.CourseImg2.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="coursesImg" />
                                <TextLayout>
                                    <h6>
                                    {JSONData.instituteJson.Courses.CourseName2}
                                    </h6>
                                    <p>
                                    {JSONData.instituteJson.Courses.CourseDesc2}
                                    </p>
                                    <DetailsList>
                                        <DetailsListItem>
                                            <RightArrowIcon />
                                            <DetailsText>
                                            {JSONData.instituteJson.Courses.DetailsText3}
                                            </DetailsText>
                                        </DetailsListItem>
                                        <DetailsListItem>
                                            <RightArrowIcon />
                                            <DetailsText>
                                            {JSONData.instituteJson.Courses.DetailsText4}
                                            </DetailsText>
                                        </DetailsListItem>
                                    </DetailsList>
                                    <ApplyNowBtn>
                                    {JSONData.instituteJson.Courses.ApplyNowBtn}
                                    </ApplyNowBtn>
                                </TextLayout>
                            </CoursesCard>
                        </Col>
                        <Col lg={4}>
                            <CoursesCard>
                                <GatsbyImage
                                    image={JSONData.instituteJson.Courses.CourseImg3.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="coursesImg" />
                                <TextLayout>
                                    <h6>
                                    {JSONData.instituteJson.Courses.CourseName3}
                                    </h6>
                                    <p>
                                    {JSONData.instituteJson.Courses.CourseDesc3}
                                    </p>
                                    <DetailsList>
                                        <DetailsListItem>
                                            <RightArrowIcon />
                                            <DetailsText>
                                            {JSONData.instituteJson.Courses.DetailsText5}
                                            </DetailsText>
                                        </DetailsListItem>
                                        <DetailsListItem>
                                            <RightArrowIcon />
                                            <DetailsText>
                                            {JSONData.instituteJson.Courses.DetailsText6}
                                            </DetailsText>
                                        </DetailsListItem>
                                    </DetailsList>
                                    <ApplyNowBtn>
                                    {JSONData.instituteJson.Courses.ApplyNowBtn}
                                    </ApplyNowBtn>
                                </TextLayout>
                            </CoursesCard>
                        </Col>
                    </Row>
                </Container>
            </CoursesSection>
        );
}

export default Courses;
