import React from 'react';
import {ImageTextSection,CustomRow,TextLayout,RightArrowIcon,ContactUsBtn,ImageHolder
} from './imagetext2.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText2 = () => {
    const JSONData  = useStaticQuery(graphql`{
  instituteJson {
    ImageText2 {
      Heading
      Description1
      ListText1
      ListText2
      ListText3
      ContactUsBtn
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
        return (
            <ImageTextSection id="imagetext2Container">
                <Container>
                    <CustomRow>
                        <Col md={12} lg={6}>
                            <ImageHolder>
                                <GatsbyImage
                                    image={JSONData.instituteJson.ImageText2.Img.childImageSharp.gatsbyImageData}
                                    alt="" />
                            </ImageHolder>
                        </Col>
                        <Col md={12} lg={6}>
                            <TextLayout>
                                <h4>
                                {JSONData.instituteJson.ImageText2.Heading}
                                </h4>
                                <p>
                                {JSONData.instituteJson.ImageText2.Description1}
                                </p>
                                <ul>
                                    <li>
                                        <RightArrowIcon />
                                        <p>{JSONData.instituteJson.ImageText2.ListText1}</p>
                                    </li>
                                    <li>
                                        <RightArrowIcon />
                                        <p>{JSONData.instituteJson.ImageText2.ListText2}</p>
                                    </li>
                                    <li>
                                        <RightArrowIcon />
                                        <p>{JSONData.instituteJson.ImageText2.ListText3}</p>
                                    </li>
                                </ul>

                                <ContactUsBtn>
                                {JSONData.instituteJson.ImageText2.ContactUsBtn}
                                </ContactUsBtn>
                            </TextLayout>
                        </Col>
                    </CustomRow>
                </Container>
            </ImageTextSection>
        );
}

export default ImageText2;
