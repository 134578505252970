import React from 'react';
import {AboutSection,CustomRow,ImgHolder,AboutLayout,TextLayout
} from './about.style';
import {Col,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const AboutPage = () => {
    const JSONData  = useStaticQuery(graphql`{
  instituteJson {
    About {
      AboutImg {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      AboutHeading
      Description1
      Description2
    }
  }
}
`);
        return (
            <AboutSection id="aboutContainer">
                <Container>
                    <CustomRow>
                        <Col md="5">
                        <ImgHolder>
                            <GatsbyImage
                                image={JSONData.instituteJson.About.AboutImg.childImageSharp.gatsbyImageData}
                                alt=""
                                className="aboutImg" />
                            </ImgHolder>
                        </Col>
                        <Col md="7">
                            <AboutLayout>
                                <TextLayout>
                                    <h3>
                                    {JSONData.instituteJson.About.AboutHeading}
                                    </h3>
                                    <p>
                                    {JSONData.instituteJson.About.Description1}
                                    </p>
                                    <p>
                                    {JSONData.instituteJson.About.Description2}
                                    </p>
                                </TextLayout>
                            </AboutLayout>
                        </Col>
                    </CustomRow>
                </Container>
            </AboutSection>
        );
}

export default AboutPage;
