import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,FooterCol,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,FooterInner,FooterMenu,FooterSocial,BottomFooterPara,
    BottomFooterWrapper,BottomLink} from './footer.style';

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query{
        instituteJson {
            Footer {
                DelayConstant
                FooterCol1{
                    FooterLogo
                    FooterPara
                }
                FooterCol2{
                    FooterTitle
                    FooterPara
                }
                FooterCol3{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                    }
                }
                FooterCol4{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                        Href
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
            }
        }
    }
`);
    return (
        <div>
        <FooterSection>
            <FooterInner>
                <Container>
                    <Row>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <img src={JSONData.instituteJson.Footer.FooterCol1.FooterLogo} alt=""/>
                                <p>
                                {JSONData.instituteJson.Footer.FooterCol1.FooterPara}
                                </p>
                                <FooterSocial href="#" aria-label={"Fb Link"}>
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label={"Twitter Link"}>
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label={"Linkedin Link"}>
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label={"Instagram Link"}>
                                    <InstaIcon/>
                                </FooterSocial>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <h5>{JSONData.instituteJson.Footer.FooterCol2.FooterTitle}</h5>
                                <p>
                                {JSONData.instituteJson.Footer.FooterCol2.FooterPara}
                                </p>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <h5>{JSONData.instituteJson.Footer.FooterCol3.FooterTitle}</h5>
                                <ul>
                                {
                                    JSONData.instituteJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                    return <li>
                                        <FooterMenu href="#">
                                            {item.FooterMenu}
                                        </FooterMenu>
                                    </li>
                                    })
                                }
                                </ul>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                            <h5>{JSONData.instituteJson.Footer.FooterCol4.FooterTitle}</h5>
                                <ScrollSpy offset={-59} items={JSONData.instituteJson.Footer.Items} currentClassName="is-current">
                                {
                                    JSONData.instituteJson.Footer.FooterCol4.FooterUl.map((item,idx) => {
                                    return <li>
                                        <AnchorLink offset={55} href={item.Href}>
                                            {item.FooterMenu}
                                        </AnchorLink>
                                    </li>
                                    })
                                }
                                </ScrollSpy>
                            </FooterCol>
                        </Col>
                    </Row>
                <BottomFooterWrapper>
                    <Row>
                        <Col md="12">
                            <BottomFooterPara>
                                {JSONData.instituteJson.Footer.BottomFooterPara}
                                <BottomLink rel="noreferrer" href={JSONData.instituteJson.Footer.BottomFooterLink} target="_blank">
                                {JSONData.instituteJson.Footer.BottomFooterName}
                                </BottomLink>
                            </BottomFooterPara>
                        </Col>
                    </Row>
                </BottomFooterWrapper>
            </Container>
            </FooterInner>
        </FooterSection>

        </div>
    );
}

export default Footer;
