import React from 'react';
import {ImageTextSection,CustomRow,TextLayout,
    LeftIcon,ContactUsBtn,ImageHolder
} from './imagetext3.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText3 = () => {
    const JSONData  = useStaticQuery(graphql`{
  instituteJson {
    ImageText3 {
      Heading
      Description1
      ListText1
      ListText2
      ListText3
      ListImage1
      ListImage2
      ListImage3
      ContactUsBtn
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
        return (
            <ImageTextSection id="imagetext3Container">
                <Container>
                    <CustomRow>
                        <Col md={12} lg={6}>
                            <TextLayout>
                                <h4>
                                {JSONData.instituteJson.ImageText3.Heading}
                                </h4>
                                <p>
                                {JSONData.instituteJson.ImageText3.Description1}
                                </p>
                                <ul>
                                    <li>
                                        <LeftIcon alt="" src={JSONData.instituteJson.ImageText3.ListImage1}/>
                                        <p>{JSONData.instituteJson.ImageText3.ListText1}</p>
                                    </li>
                                    <li>
                                        <LeftIcon alt="" src={JSONData.instituteJson.ImageText3.ListImage2} />
                                        <p>{JSONData.instituteJson.ImageText3.ListText2}</p>
                                    </li>
                                    <li>
                                        <LeftIcon alt="" src={JSONData.instituteJson.ImageText3.ListImage3} />
                                        <p>{JSONData.instituteJson.ImageText3.ListText3}</p>
                                    </li>
                                </ul>
                                <ContactUsBtn>
                                {JSONData.instituteJson.ImageText3.ContactUsBtn}
                                </ContactUsBtn>
                            </TextLayout>
                        </Col>
                        <Col md={12} lg={6}>
                            <ImageHolder>
                                <GatsbyImage
                                    image={JSONData.instituteJson.ImageText3.Img.childImageSharp.gatsbyImageData}
                                    alt="" />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                </Container>
            </ImageTextSection>
        );
}

export default ImageText3;
