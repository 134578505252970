import React from 'react';
import {FeaturesSection,FeaturesSingle
} from './features.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Features{
                        FeaturesImg1
                        FeaturesHeading1
                        FeaturesDesc1
                        FeaturesImg2
                        FeaturesHeading2
                        FeaturesDesc2
                        FeaturesImg3
                        FeaturesHeading3
                        FeaturesDesc3
                        FeaturesImg4
                        FeaturesHeading4
                        FeaturesDesc4
                    }
                }
            }
    `);
        return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <Row>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <img
                                src={JSONData.instituteJson.Features.FeaturesImg1}
                                alt=""
                            />
                            <h6>
                            {JSONData.instituteJson.Features.FeaturesHeading1}
                            </h6>
                            <p>
                            {JSONData.instituteJson.Features.FeaturesDesc1}
                            </p>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <img
                                src={JSONData.instituteJson.Features.FeaturesImg2}
                                alt=""
                            />
                            <h6>
                            {JSONData.instituteJson.Features.FeaturesHeading2}
                            </h6>
                            <p>
                            {JSONData.instituteJson.Features.FeaturesDesc2}
                            </p>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <img
                                src={JSONData.instituteJson.Features.FeaturesImg3}
                                alt=""
                            />
                            <h6>
                            {JSONData.instituteJson.Features.FeaturesHeading3}
                            </h6>
                            <p>
                            {JSONData.instituteJson.Features.FeaturesDesc3}
                            </p>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <img
                                src={JSONData.instituteJson.Features.FeaturesImg4}
                                alt=""
                            />
                            <h6>
                            {JSONData.instituteJson.Features.FeaturesHeading4}
                            </h6>
                            <p>
                            {JSONData.instituteJson.Features.FeaturesDesc4}
                            </p>
                        </FeaturesSingle>
                    </Col>
                </Row>
            </Container>
        </FeaturesSection>
    );
}

export default Features;
