import React,{Component}from 'react';
import {ContactWrapper,CustomRow,LogoLayout,LogoHolder,HeadingLayout,ContactFormHeading,
    ContactFormRight,Form,FormGroup,FormGroupSubmit,NameInput,ContactFormSpanErr,TextCenter,SubmitBtn,
    ContactFormSpanSuccess
} from './contact.style';
import {Container,Row,Col} from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from 'gatsby';

const WRNG_MSG_TIMEOUT = 3000;

class Contact extends Component{

    constructor(props) {
        super(props);
        this.state = {
            errors:[],
            contactForm_name:"",
            contactForm_email:"",
            contactForm_course:"",
            contactForm_phone:""
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.contactForm_name === "")
        {
            error_flag = true;
            errors['contactForm_name'] = "Please enter name!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_email === "")
        {
            error_flag = true;
            errors['contactForm_email'] = "Please enter email!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_email !== "")
        {
            var patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!patternEmail.test(this.state.contactForm_email)) {
                error_flag = true;
                errors["contactForm_email"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contactForm_course === "")
        {
            error_flag = true;
            errors["contactForm_course"] = "Please enter a course!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_phone === "")
        {
            error_flag = true;
            errors["contactForm_phone"] = "Please enter mobile no!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_phone !== "")
        {
            var val = this.state.contactForm_phone;
            var len	=	val.length;
            var reg = /^\d+$/;
            if( !reg.test( val ) )
            {
                error_flag = true;
                errors["contactForm_phone"] = "Please enter valid phone!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
            else
            {
                if(len < 10)
                {
                    error_flag = true;
                    errors["contactForm_phone"] = "Please enter valid phone!";
                    setTimeout(function(){
                        this.setState({errors:{}});
                   }.bind(this),WRNG_MSG_TIMEOUT);
                }
            }
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    contactFormSubmit = (e)=>{
        e.preventDefault();
        if(!this.validateForm())
        {
            //SUCCESS 
            //TO DO
            console.log("Name: "+this.state.contactForm_name);
            console.log("Email: "+this.state.contactForm_email);
            console.log("Phone: "+this.state.contactForm_phone);
            console.log("Course: "+this.state.contactForm_course);

            document.getElementById("success_contactForm_message").innerHTML = "Thanks for contacting us.";
            this.setState({
                contactForm_name:"",
                contactForm_email:"",
                contactForm_phone:"",
                contactForm_course:"",
                errors:[]
            },()=>{
                setTimeout(function(){
                    document.getElementById("success_contactForm_message").innerHTML = "";
               },WRNG_MSG_TIMEOUT);
            });
        }
    }

    render() {
        return (
            <ContactWrapper id="contactContainer">
                <Container>
                    <CustomRow>
                        <Col md={7}>
                            <LogoLayout>
                            {
                                this.props.instituteJson.Contact.LogoHolder.map((item,idx) => {
                                return (
                                    <LogoHolder>
                                        <GatsbyImage image={item.Img.childImageSharp.gatsbyImageData} alt="" />
                                    </LogoHolder>
                                );
                                })
                            }
                            </LogoLayout>
                        </Col>
                        <Col md={5}>
                            <ContactFormRight>
                                <Row>
                                    <Col md={12}>
                                        <HeadingLayout>
                                            <ContactFormHeading>
                                            {this.props.instituteJson.Contact.ContactFormHeading} 
                                            </ContactFormHeading>
                                        </HeadingLayout>
                                    </Col>
                                </Row>
                                <Form id="contactForm" method="post" onSubmit={this.contactFormSubmit}>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Name" type="text" placeholder="Name" name="contactForm_name" onChange={this.handleChange} value={this.state.contactForm_name}/>
                                                <ContactFormSpanErr id="err_contactForm_name">{this.state.errors.contactForm_name}</ContactFormSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Email" type="text" placeholder="E-mail" name="contactForm_email" onChange={this.handleChange} value={this.state.contactForm_email}/>
                                                <ContactFormSpanErr id="err_contactForm_email">{this.state.errors.contactForm_email}</ContactFormSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Phone" type="text" placeholder="Phone" name="contactForm_phone" onChange={this.handleChange} value={this.state.contactForm_phone}/>
                                                <ContactFormSpanErr id="err_contactForm_phone">{this.state.errors.contactForm_phone}</ContactFormSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Course" type="text" placeholder="Course" name="contactForm_course" onChange={this.handleChange} value={this.state.contactForm_course}/>
                                                <ContactFormSpanErr id="err_contactForm_course">{this.state.errors.contactForm_course}</ContactFormSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroupSubmit>
                                                <TextCenter>
                                                    <SubmitBtn href="#" onClick={this.contactFormSubmit}>Submit</SubmitBtn>
                                                </TextCenter>
                                            </FormGroupSubmit>
                                        </Col>
                                    </Row>
                                    <ContactFormSpanSuccess id="success_contactForm_message"></ContactFormSpanSuccess>
                                </Form>
                            </ContactFormRight>
                        </Col>
                    </CustomRow>
                </Container>
            </ContactWrapper>
        );
    }
}
const ContactSection = () => (
    <StaticQuery
        query={graphql`{
  instituteJson {
    Contact {
      ContactFormHeading
      LogoHolder {
        Img {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`}
        render={(data) => (
            <Contact instituteJson={data.instituteJson}/>
        )}
    />
  )
export default ContactSection;
